import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import $ from "jquery"
import Metatags from "../../components/metatags"
import Navbar from "../../components/navbar"
import Gallery from "../../components/projects-gallery"
import MobGallery from "../../components/mob-gallery-comp"
import Footer from "../../components/footer"

const Iese = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  return (
    <>
      <Metatags
        title="daucodesigns | proyecto iese"
        description="detrás de cada producto, hay una historia"
        url="https://daucodesigns.com"
        image="https://daucodesigns.com/logo-daucodesigns.png"
        keywords="muebles, design"
      />
      <Navbar />
      <div className="height40" />
      <div className="height20" />
      <div id="project-header">
        <Container style={{ maxWidth: "1300px" }}>
          <Row>
            <Col>
              <div className="text-center projects">
                <h4 className="marcas-title nuestras-marcas-title">iese</h4>
                <h5>barcelona</h5>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
      </div>
      {windowWidth >= 768 ? (
        <Gallery
          imagesArray={data.projectImages.nodes}
          company="iese"
        />
      ) : (
        <MobGallery
          imagesArray={data.projectImages.nodes}
          ratio={765 / 1460}
          company="iese"
          windowWidth={windowWidth}
          leftArrow={data.leftArrow}
          rightArrow={data.rightArrow}
        />
      )}
      <div className="height40" />
      <Container>
        <Row>
          <Col>
            <div className="text-center projects">
              <h5>Innovación en formación</h5>
            </div>
          </Col>
        </Row>
        <div className="height20" />
        <Row>
          <Col>
            <p>
              Conocida por su formación de alta calidad y su innovación, esta
              universidad quería dar un cambio a su tradicional biblioteca para
              convertirla en un espacio de estudio, interacción y colaboración
              entre los estudiantes, que fomentara el trabajo en equipo y la
              excelencia.
            </p>
            <p>
              Los nuevos conceptos de espacios comunes híbridos que aporta la
              serie PARCS de Bene, permite crear diferentes zonas, tal como
              podemos encontrar en un parque de una ciudad. Combinando asientos
              bajos, con mesas de trabajo y sofás altos cerrados formando
              espacios de reunión o trabajo concentrado, se lograba aunar ambos
              mundos. Para facilitar el uso de las herramientas necesarias, se
              integraron conexiónes y usb de carga.
            </p>
            <p>
              Se aplicaron en los tejidos una combinación de colores
              corporativos.
            </p>
            <p>
              Los productos utilizados fueron la serie Causeways, Club y
              American Dinner de Bene.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="height40" />
      <div className="height40" />
      <Footer />
    </>
  )
}

export default Iese

export const query = graphql`
  {
    leftArrow: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    rightArrow: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projectImages: allFile(
      filter: { relativePath: { regex: "/projects/iese/" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  }
`
